.submit{
    padding: 5px 7px 5px !important;
    margin: 5px;
    font-size: 18px !important;
    background-color: #ffffff;
    text-shadow: 1px 1px #ffffff;
    color: #000000;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: 1px solid #ffffff;
    cursor: pointer;
    }

.form{
    text-align: center;
}
.loader{
    text-align: center;
}